<template>
  <div
    class="relative"
    v-on-click-outside="toggleModal"
    v-if="impersonateModalOpen"
  >
    <impersonate-modal class="absolute bottom-0" @impersonated="impersonated" />
  </div>
  <div
    class="border-white/10 flex rounded relative overflow-hidden bg-login-bg pb-2"
    :class="sidebarExpanded ? 'justify-left pl-4' : 'justify-center'"
  >
    <router-link
      :to="{ name: ROUTE_ACCOUNT }"
      v-tooltip="{
        content: $t('SIDEBAR.PROFILE.TITLE'),
        onShow: () => !sidebarExpanded
      }"
      class="flex cursor-pointer relative focus:outline-none max-w-full group/sidebar py-3 text-white"
      data-cy="impersonate-block-icon"
    >
      <icon-base
        class="place-self-center shrink-0 basis-6 icon-profile"
        height="24"
        width="24"
        view-box="0 0 48 48"
        icon="profile"
      />
    </router-link>
    <div
      v-if="sidebarExpanded"
      data-cy="impersonate-block-data"
      class="grow whitespace-nowrap overflow-hidden px-4 py-[1px] place-self-center text-white/80 text-sm font-medium group-hover/sidebar:text-white transition-colors duration-200"
    >
      <div class="flex shrink flex-col justify-between space-y-2">
        <router-link
          :to="{ name: ROUTE_ACCOUNT }"
          v-tooltip="fullUserName"
          class="truncate"
          data-cy="impersonate-block-name"
        >
          {{ fullUserName }}
        </router-link>

        <span
          class="text-xs font-normal space-x-2 pb-1"
          v-if="isAdmin || alreadyImpersonatingSomeone"
        >
          <span
            v-if="alreadyImpersonatingSomeone"
            class="uppercase cursor-pointer font-semibold bg-[#F1F6FD1A] p-1 rounded-5 px-2"
            data-cy="impersonate-block-exit-button"
            @click="stopImpersonating"
          >
            {{ $t("COMMON.EXIT") }}
          </span>
          <span
            v-else
            class="cursor-pointer bg-[#9EC6F2] text-[#272A40] p-1 px-2 uppercase rounded-5 font-semibold"
            data-cy="impersonate-block-select-button"
            @click.self="toggleModal"
          >
            {{ t("COMMON.IMPERSONATE") }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import type { IUser } from "@/models/users";
import { formatUserFullName } from "@/helpers/formatting";
import { ROUTE_ACCOUNT } from "@/router/routes";
import { SUPER_ADMIN, ADMIN } from "@/helpers/constants";
import { useImpersonate } from "@/hooks/auth";

import ImpersonateModal from "@/components/sidebar/ImpersonateModal.vue";

const { sidebarExpanded = false } = defineProps<{
  sidebarExpanded: boolean;
}>();

const { getters, dispatch, commit } = useStore();
const { t } = useI18n();
const { alreadyImpersonatingSomeone, setImpersonateMessage } = useImpersonate();

const impersonateModalOpen = ref(false);

const user = computed<IUser>(() => getters["auth/user"]);

const isAdmin = computed(
  () =>
    user.value?.roles.includes(ADMIN) || user.value?.roles.includes(SUPER_ADMIN)
);

const fullUserName = computed(() => formatUserFullName(user.value));

const toggleModal = () => {
  impersonateModalOpen.value = !impersonateModalOpen.value;
};
const stopImpersonating = async () => {
  commit("setPreventGlobalMessage", true);
  await dispatch("auth/stopImpersonating", { canRedirect: false });
  setImpersonateMessage();
  window.location.assign(window.location.pathname);
  commit("resetHistoryStack", null, { root: true });
};

const impersonated = () => {
  setImpersonateMessage();
  impersonateModalOpen.value = false;
  window.location.assign(window.location.pathname);
};
</script>
